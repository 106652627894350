<template>
  <div class="user-center">
    <NuxtLoadingIndicator />
    <div class="left" :style="`width: ${leftWidth}rem;`">
      <ClientOnly>
        <UserCenterLeftTabs />
      </ClientOnly>
      <div class="toggle" @click="handleToggle">
        <font-awesome-icon :icon="['fas', 'chevron-left']" v-if="isExpand" />
        <font-awesome-icon :icon="['fas', 'chevron-right']" v-else />
      </div>
    </div>
    <div class="right">
      <ClientOnly>
        <UserCenterRight>
          <NuxtPage />
        </UserCenterRight>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup>
import useUserProfileStore from '@/stores/userProfile'

const userProfile = useUserProfileStore()

const leftWidth = ref(1.3)
const isExpand = ref(userProfile.isExpand ?? true)

onMounted(() => {
  isExpand.value ? (leftWidth.value = 1.41) : (leftWidth.value = 0.5)
})

const handleToggle = () => {
  isExpand.value = !isExpand.value
  isExpand.value ? (leftWidth.value = 1.41) : (leftWidth.value = 0.5)
  userProfile.setExpand(isExpand.value)
}

</script>

<style lang="less" scoped>
.user-center {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .left {
    position: relative;
    height: 100%;
    // overflow: hidden;
    background-color: var(--third-color);
    border-right: 1px solid #404040;
    transition: all 0.3s ease;

    .toggle {
      position: absolute;
      right: -10px;
      top: 18px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background-color: #fff;
      color: rgb(53, 137, 170);
      text-align: center;
      line-height: 23px;
      cursor: pointer;
      z-index: 10;
      font-size: 14px;
    }
  }

  .right {
    flex: 1;
    height: 100vh;
  }
}
</style>
